// React
import React from "react";
import {useLocation} from "@reach/router";
import {navigate} from "gatsby";

// Components
import PageNotFound from "../../components/PageNotFound";
import LoadingIndicator from "../../components/LoadingIndicator";
import {PublicNavbar} from "../../components/Navbars";
import Seo from "../../components/Seo";
import Footer from "../../components/Footer";
import FreelancerCard from "../../components/FreelancerCard";
import FreelancerDialog from "../../components/FreelancerDialog";
import EmptyStateView from "../../components/EmptyStateView";
import NavBreadCrumbs from "../../components/NavBreadCrumbs";
import BodyContainer from "../../components/BodyContainer";
import FreelancerProfile from "../../components/FreelancerProfile";
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";

// Material UI
import {Avatar, CardHeader, Button, Box, Typography, List, Divider, Tooltip} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';

import {AvatarGroup} from '@material-ui/lab';
import {People} from "@material-ui/icons";

// Other libs
import queryString from "query-string";
import * as routes from "../../constants/routes";
import {minTeamMembers} from "../../constants/settings";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardHeaderRoot: {
    padding: 0
  },
  avatarGroup: {
    marginTop: theme.spacing(1),
  },
  subHeaderText: {
    marginBottom: theme.spacing(2),
  },
  teamAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  teamAboutText: {
    marginTop: theme.spacing(3),
  },
  teamLeadAboutText: {
    marginTop: theme.spacing(3),
  },
  applyButton: {
    marginBottom: "auto",
    marginTop: "auto",
  },
  requestButtonSpan: {
    marginBottom: "auto",
    marginTop: "auto",
    marginLeft: theme.spacing(1),
  },
  memberContainer: {
    marginTop: theme.spacing(3),
  },
  listRoot: {
    padding: 0
  }
}));

export default function TeamProfile(props) {
  const firebase = React.useContext(FirebaseContext);
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);
  const teamRef = firebase ? firebase.team(parsedQueries.uid) : null;
  const [team, loadingTeam] = useFetchFirestore(teamRef);
  const memberIdsToFetch = team ? (
    Object.keys(team.members)
      .map(userId => team.members[userId])
      .filter(({status}) => status === "active")
      .map(({userId}) => userId)
  ) : [];
  const memberProfilesRef = memberIdsToFetch.length > 0 ? firebase.users().where("uid", "in", memberIdsToFetch) : null;
  const [memberProfiles, loadingMemberProfiles] = useFetchFirestore(memberProfilesRef, {type: "collection"});

  const classes = useStyles(props);

  const handleRequestProposal = () => console.log("handleRequestProposal");

  const handleApplyClick = () => navigate(
    routes.freelancerTeamTab
      .replace(":teamId", team.uid)
      .replace(":tab", "apply")
  );

  const handleSelectMember = memberId => {
    parsedQueries.member = memberId;
    return navigate(queryString.stringifyUrl({
      url: location.pathname,
      query: parsedQueries
    }));
  };

  const handleCloseDialog = () => {
    delete parsedQueries.member;
    return navigate(queryString.stringifyUrl({
      url: location.pathname,
      query: parsedQueries
    }));
  };

  if (loadingTeam || loadingMemberProfiles) {
    return <LoadingIndicator/>;
  } else if (!team || !memberProfiles || memberProfiles.length === 0) {
    return <PageNotFound/>;
  }

  const teamLead = memberProfiles.filter(({uid}) => uid === team.teamLeadId)[0];
  if (!teamLead) {
    return <PageNotFound/>;
  }

  const selectedMember = parsedQueries.member ? memberProfiles.filter(({uid}) => uid === parsedQueries.member)[0] : null;
  const members = memberProfiles
    .filter(({uid}) => uid !== teamLead.uid);
  return (
    <Box>
      <Seo
        title={`Hire team ${team.name} | Maxer`}
        description={team.about}
        pathname={location.pathname}
      />
      <PublicNavbar/>
      {selectedMember && (
        <FreelancerDialog
          freelancer={selectedMember}
          handleClose={handleCloseDialog}
          DialogBodyContent={
            <FreelancerProfile
              freelancer={selectedMember}
              team={team}
            />
          }
          // DialogActionsContent={
          //   (selectedMember.uid === teamLead.uid) ? (
          //     <Button color="primary" startIcon={<Message/>}>Contact</Button>
          //   ) : (
          //     <Typography
          //       variant="caption">{`Want to hire ${selectedMember.firstName} ${selectedMember.lastName}? Contact ${team.name}'s team lead ${teamLead.firstName} ${teamLead.lastName}.`}</Typography>
          //   )
          // }
        />
      )}
      <BodyContainer>
        <NavBreadCrumbs
          crumbs={[
            {
              label: "Teams",
              destination: routes.teams,
            },
            {
              label: team.name,
            }
          ]}
          bottomGutterSpacing={2}
          topGutterSpacing={2}
        />
        <Divider/>
      </BodyContainer>
      <BodyContainer topGutter bottomGutter>
        <Box className={classes.headerContainer}>
          <CardHeader
            classes={{
              root: classes.cardHeaderRoot,
            }}
            avatar={
              <Avatar
                src={team.profilePhoto}
                className={classes.teamAvatar}
                variant="rounded"
              />
            }
            title={<Typography variant="h5" component="h1">{team.name}</Typography>}
            subheader={
              (memberProfiles && memberProfiles.length > 0) ? (
                <AvatarGroup max={4} className={classes.avatarGroup}>
                  {memberProfiles
                    .map(({profilePhoto, uid}) =>
                      <Avatar alt="Remy Sharp" src={profilePhoto} key={uid}/>
                    )
                  }
                </AvatarGroup>
              ) : (
                <AvatarGroup max={4} className={classes.avatarGroup}>
                  {[1, 2, 3, 4, 5].map((num) =>
                    <Avatar key={num}>.</Avatar>
                  )}
                </AvatarGroup>
              )
            }
            disableTypography
          />
          <div className={classes.headerButtonContainer}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.applyButton}
              onClick={handleApplyClick}
            >
              Apply as member
            </Button>
            <Tooltip
              title={memberProfiles.length < minTeamMembers ? `${minTeamMembers - memberProfiles.length} more ${minTeamMembers - memberProfiles.length === 1 ? "" : "s"} member required to receive requests` : `Create a request`}>
              {/*Span is workaround for disabled status: https://material-ui.com/components/tooltips/#DisabledTooltips.js*/}
              <span className={classes.requestButtonSpan}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={memberProfiles.length < minTeamMembers}
                  onClick={handleRequestProposal}
                >
                  Request to hire
                </Button>
              </span>
            </Tooltip>
          </div>
        </Box>
        <Typography className={classes.teamAboutText}>
          {team.about}
        </Typography>
        <Box className={classes.memberContainer}>
          <Typography variant="h6" component="h2" className={classes.subHeaderText}>Team lead</Typography>
          <FreelancerCard
            freelancer={teamLead}
            // CardActionsContent={<Button color="primary" startIcon={<Message/>}>Contact</Button>}
            handleActionAreaClick={() => handleSelectMember(teamLead.uid)}
          />
        </Box>
        <Box className={classes.memberContainer}>
          <Typography variant="h6" component="h2" className={classes.subHeaderText}>Team members</Typography>
          <List className={classes.listRoot}>
            {members.length > 0 ? (
              members
                .map(freelanceMember =>
                  <FreelancerCard
                    key={freelanceMember.uid}
                    freelancer={freelanceMember}
                    handleActionAreaClick={() => handleSelectMember(freelanceMember.uid)}
                    hideAbout
                  />
                )
            ) : (
              <EmptyStateView
                text="No team members joined yet"
                icon={<People/>}
                topSpacing={5}
              />
            )}
          </List>
        </Box>
      </BodyContainer>
      <Footer/>
    </Box>
  );
}

TeamProfile.propTypes = {};
