// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingIndicator from "../LoadingIndicator";

const useStyles = makeStyles((theme) => ({}));

export default function SimpleDialog(props) {
  const {
    id,
    open,
    isLoading,
    toggleDialog,
    title,
    description,
    primaryActionText,
    handlePrimaryAction,
    secondaryActionText,
    handleSecondaryAction,
  } = props;
  const classes = useStyles();

  if (isLoading) {
    return (
      <Dialog
        open={open}
        onClose={() => console.log("loading")}
        id={"loading-dialog"}
        className={classes.root}
      >
        <DialogContent>
          <LoadingIndicator
            message="Loading..."
            inheritHeight={true}
            marginTop={40}
            marginBottom={40}
          />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={toggleDialog}
      id={id || "simple-dialog"}
      className={classes.root}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      {((secondaryActionText && handleSecondaryAction) || (primaryActionText && handlePrimaryAction)) && (
        <DialogActions>
          {secondaryActionText && handleSecondaryAction && (
            <Button onClick={handleSecondaryAction} color="primary">
              {secondaryActionText}
            </Button>
          )}
          {primaryActionText && handlePrimaryAction && (
            <Button
              onClick={handlePrimaryAction}
              variant="contained"
              color="primary"
              id="primary-button"
            >
              {primaryActionText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.any.isRequired,
  primaryActionText: PropTypes.string,
  handlePrimaryAction: PropTypes.func,
  secondaryActionText: PropTypes.string,
  handleSecondaryAction: PropTypes.func,
};
